import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import GuestCheckout, { IGuestCheckoutProps } from "./GuestCheckout";
import AddCreditCard, { IAddCreditCardProps } from "./AddCreditCard";
import reportWebVitals from "./reportWebVitals";
import Health from "./Health";
import CheckoutErrorPage from "./CheckoutErrorPage";
import { ChallengeProtocol } from "./challengeui/challengeProtocol";
import $ from "jquery";

const guestcheckoutProps = ReadGuestCheckoutProp(window.location.href);
//const addCcProps = ReadAddCreditCardProp("https://payppe.microsoft.com/anonymous?sessionId=59cb054b-3202-47f7-bc57-54f73fd81d50"); 
const addCcProps = ReadAddCreditCardProp(window.location.href); 

if (new URL(window.location.href).pathname == "/health") {
  ReactDOM.render(
    <React.StrictMode>
      <Health />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else if (new URL(window.location.href).pathname == "/error") {
  ReactDOM.render(
    <React.StrictMode>
      <CheckoutErrorPage />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else if (new URL(window.location.href).pathname == "/challengeui") {
  // set the env to 'int' if guestcheckoutProps is null
  let env = "int"

  switch (new URL(window.location.href).hostname) {
    case "payint.microsoft.com":
      env = "int";
    break;
    case "payppe.microsoft.com":
      env = "ppe";
    break;
    case "pay.microsoft.com":
      env = "prod";
    break;
  }

  ReactDOM.render(
    <React.StrictMode>
      <ChallengeProtocol env={env}/>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else if (new URL(window.location.href).pathname.includes("/addCard")) {
  addHeaderFooter();
  ReactDOM.render(
    <React.StrictMode>
      <body>
          <div id="customHeader"></div>
          {/* generate the green id, send it to risk to fingerprint (it will be javascript) and pass that along */}
          <main>
          <AddCreditCard 
            env={addCcProps.env}  
            sessionId={addCcProps.sessionId}
            country={addCcProps.country}
            language={addCcProps.language}
            partner={addCcProps.partner}
            paymentType={addCcProps.paymentType}
            paymentFamily={addCcProps.paymentFamily}
            scenario={addCcProps.scenario}
          />
          </main>
          <div id="customFooter"></div>
      </body>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else if (guestcheckoutProps == null) {
  ReactDOM.render(
    <React.StrictMode>
      <div>404 Not Found :(</div>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <GuestCheckout
        checkoutId={guestcheckoutProps.checkoutId}
        country={guestcheckoutProps.country}
        env={guestcheckoutProps.env}
        language={guestcheckoutProps.language}
        paymentProviderId={guestcheckoutProps.paymentProviderId}
        platformType={guestcheckoutProps.platformType}
        redirectUrl={guestcheckoutProps.redirectUrl}
        testScenarios={guestcheckoutProps.testScenarios}
      />
    </React.StrictMode>,
    document.getElementById("root")
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function addHeaderFooter () {   
  <script src="http://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>

  $(document).ready(function() {
    $.ajax({
      url: "https://uhf.microsoft.com/us/shell/xml/MSPayCard?headerid=MSPayCardHeader&footerid=MSPayCardFooter",
      method: 'GET',
      dataType: "xml",
    }).done((msg) => {
      console.log("start");
      const responsexml = msg;
      const cssIncludes = $(responsexml).find("cssIncludes")[0].childNodes[1] as CDATASection;
      const trimCssData = cssIncludes.data.trim();
      const styles = $.parseHTML(trimCssData);
      $("head").append(styles);

      const headerHtml = ($(responsexml).find("headerHtml")[0].childNodes[1] as CDATASection).data.trim();
      (document.getElementById("customHeader") as HTMLFormElement).innerHTML = headerHtml;

      const footerHtml = ($(responsexml).find("footerHtml")[0].childNodes[1] as CDATASection).data.trim();
      (document.getElementById("customFooter") as HTMLFormElement).innerHTML = footerHtml;
    });
  });
}

function ReadGuestCheckoutProp(href: string): IGuestCheckoutProps | null {
  // href example:
  // http://localhost:3000/payment-providers/stripe/checkout/123/start?redirectUrl=teams.microsoft.com&testScenarios=px.3pp.stripe.guestcheckout.success,px.sellermarket.stripe.us&platformType=msteams

  const parts = href.split("/");

  if (parts.length != 8) {
    return null;
  }

  const queryParams = new URL(href).searchParams;
  const checkoutId = href.split("/")[6];
  const paymentProviderId = href.split("/")[4];
  const redirectUrl = queryParams.get("redirectUrl") ?? "";
  const testScenarios = queryParams.get("testScenarios") ?? "";
  const platformType = queryParams.get("platformType") ?? "msteams";
  const language = queryParams.get("language") ?? "en-us";
  const country = queryParams.get("country") ?? "us";

  let env = "int";

  switch (new URL(href).hostname) {
    case "payint.microsoft.com":
      env = "int";
      break;
    case "payppe.microsoft.com":
      env = "ppe";
      break;
    case "pay.microsoft.com":
      env = "prod";
      break;
  }

  return {
    checkoutId: checkoutId,
    country: country,
    env: env,
    language: language,
    paymentProviderId: paymentProviderId,
    platformType: platformType,
    redirectUrl: redirectUrl,
    testScenarios: testScenarios,
  };
}

function ReadAddCreditCardProp(href: string): IAddCreditCardProps {
  // href example:
  //  https://pay.microsoft.com/anonymous?sessionId=6b1457bf-08cf-44a4-bc52-3028113df9d8&country=us&language=en-us&parnter=xboxsettings

  const parts = href.split("/");

  // TODO: Should we be having fallback values or only throwing an error?
  const queryParams = new URL(href).searchParams;
  const language = queryParams.get("language") ?? "en-us";
  const country = queryParams.get("country") ?? "us";
  const sessionId = queryParams.get("sessionId") ?? "";
  const partner = queryParams.get("partner") ?? "xboxsettings";
  const paymentType = queryParams.get("type") ?? "amex,visa,mc,discover";
  const paymentFamily = queryParams.get("paymentFamily") ?? "credit_card";
  const scenario = queryParams.get("scenario") ?? "secondScreenAddPi";
  
  let env = "int";

  switch (new URL(href).hostname) {
    case "payint.microsoft.com":
      env = "int";
      break;
    case "payppe.microsoft.com":
      env = "ppe";
      break;
    case "pay.microsoft.com":
      env = "prod";
      break;
  }

  return {
    country: country,
    env: env,
    language: language,
    sessionId: sessionId,
    partner: partner,
    paymentType: paymentType,
    paymentFamily: paymentFamily,
    scenario: scenario,
  };
}